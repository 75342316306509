import {
  ACTIVE_SUBSCRIBER,
  EMPTY_USER,
  INACTIVE_SUBSCRIBER,
  ONLY_ONE_TIME_PURCHASER,
  PROSPECT,
  PURCHASER,
  SUBSCRIBER
} from './user-type'
import {
  HAS_EYE_CREAM_DUO_ONE_TIME,
  HAS_EYE_PROFILE,
  HAS_SERUM_PROFILE,
  HAS_SKIN_PROFILE,
  HAS_SYSTEM_ACTIVE_SUBSCRIPTION,
  HAS_SYSTEM_ONE_TIME
} from './user-trait'
import {
  onAfterAuthentication,
  onAfterAuthenticationWithGiftCard,
  onAfterEyeQuiz,
  onAfterSerumQuiz,
  onAfterSkinQuiz,
  redirectToCleanserPDP,
  redirectToMoisturizerPDP,
  redirectToNightCreamPDP,
  redirectToEyeDuoPDP,
  redirectToDayEyeCreamPDP,
  redirectToNightEyeCreamPDP,
  redirectToSerumPDP,
  redirectToShopPage,
  redirectToAccountPage,
  redirectTo422,
  redirectToPaymentUpdatePage,
  redirectToSpotTreatmentPage
} from './event-name'

const userTypeAccess = {
  [EMPTY_USER]: {
    redirectionByPathOrEventName: {
      [onAfterAuthentication]: '/quiz/start',
      [redirectToCleanserPDP]: '/personalized-face-cleanser/',
      [redirectToMoisturizerPDP]: '/personalized-day-moisturizer/',
      [redirectToNightCreamPDP]: '/personalized-night-cream/',
      [redirectToEyeDuoPDP]: '/personalized-eye-cream-duo/',
      [redirectToDayEyeCreamPDP]: '/personalized-day-eye-cream/',
      [redirectToNightEyeCreamPDP]: '/personalized-night-eye-cream/',
      [redirectToShopPage]: '/account/shop/',
      [redirectToSerumPDP]: '/personalized-serum/',
      [redirectToAccountPage]: '/account/',
      [redirectTo422]: '/account/shop/onetime/hydration-boost-day-moisturizer/',
      [redirectToPaymentUpdatePage]: '/account/settings/info/payment',
      [redirectToSpotTreatmentPage]: '/spot-treatment-serum/',
      '/account/congratulations': '/quiz/start',
      '/account/congratulations/eye': '/quiz/eye/start',
      '/account/congratulations/serum': '/quiz/serum/start',
      [onAfterSkinQuiz]: '/account/congratulations',
      [onAfterEyeQuiz]: '/account/congratulations/eye',
      [onAfterSerumQuiz]: '/account/congratulations/serum'
    }
  },
  [PROSPECT]: {
    allowedPaths: [
      '/account/congratulations',
      '/account/congratulations/eye',
      '/account/congratulations/serum'
    ],
    redirectionByPathOrEventName: {
      [onAfterAuthenticationWithGiftCard]: '/gift-redeem/success/existing',
      [onAfterAuthentication]: '/account/congratulations',
      ['/serum' + onAfterAuthentication]: '/account/congratulations/serum',
      ['/eye' + onAfterAuthentication]: '/account/congratulations/eye',
      ['/welcome-back' + onAfterAuthentication]: '/account/congratulations',
      [onAfterSkinQuiz]: '/account/congratulations',
      [onAfterEyeQuiz]: '/account/congratulations/eye',
      [onAfterSerumQuiz]: '/account/congratulations/serum',
      '/account': '/account/congratulations',
      '/account/skin/update/profile': '/account/congratulations',
      '/account/skin/preferences': '/account/congratulations',
      '/account/skin/feedback': '/account/congratulations',
      '/serum': '/account/congratulations/serum',
      '/eye': '/account/congratulations/eye',
      '/welcome-back': '/account/congratulations',
      [redirectToCleanserPDP]: '/personalized-face-cleanser/',
      [redirectToMoisturizerPDP]: '/personalized-day-moisturizer/',
      [redirectToNightCreamPDP]: '/personalized-night-cream/',
      [redirectToEyeDuoPDP]: '/personalized-eye-cream-duo/',
      [redirectToDayEyeCreamPDP]: '/personalized-day-eye-cream/',
      [redirectToNightEyeCreamPDP]: '/personalized-night-eye-cream/',
      [redirectToShopPage]: '/account/shop/',
      [redirectToSerumPDP]: '/personalized-serum/',
      [redirectToAccountPage]: '/account/',
      [redirectTo422]: '/account/congratulations',
      [redirectToPaymentUpdatePage]: '/account/congratulations',
      [redirectToSpotTreatmentPage]: '/account/congratulations'
    },
    [HAS_EYE_PROFILE]: {
      redirectionByPathOrEventName: {
        [onAfterAuthentication]: '/account/congratulations/eye'
      }
    },
    [HAS_SKIN_PROFILE]: {
      redirectionByPathOrEventName: {
        [onAfterAuthentication]: '/account/congratulations'
      }
    },
    [HAS_SERUM_PROFILE]: {
      redirectionByPathOrEventName: {
        [onAfterAuthentication]: '/account/congratulations/serum'
      }
    }
  },
  [PURCHASER]: {
    allowedPaths: [
      '/account',
      '/account/shop',
      '/account/shop/eye',
      '/account/shop/eye/:productId',
      '/account/shop/skin',
      '/account/shop/skin/:productId',
      '/account/shop/onetime/:productId',
      '/account/settings/info',
      '/account/settings/info/payment',
      '/account/settings/info/shipping',
      '/account/settings/info/password',
      '/account/settings/info/phone',
      '/account/settings/credits',
      '/account/settings/orders',
      '/account/settings/orders/:orderId',
      '/account/settings',
      '/account/support',
      '/account/support/questions',
      '/account/support/questions-result',
      '/account/shop/serum'
    ],
    redirectionByPathOrEventName: {
      ['/serum' + onAfterAuthentication]: '/account/shop/serum',
      ['/eye' + onAfterAuthentication]: '/account/shop/eye',
      ['/welcome-back' + onAfterAuthentication]: '/account',
      [onAfterAuthenticationWithGiftCard]: '/gift-redeem/success/existing',
      [onAfterAuthentication]: '/account',
      [onAfterEyeQuiz]: '/account/shop/eye',
      [onAfterSerumQuiz]: '/account/shop/serum',
      [onAfterSkinQuiz]: '/account/shop/skin',
      '/account/congratulations/eye': '/account/shop/eye',
      '/account/congratulations/serum': '/account/shop/serum',
      '/account/congratulations': '/account/shop/skin',
      '/account/subscriptions': '/account/shop',
      '/account/subscriptions/:subscriptionId': '/account/shop',
      '/account/subscriptions/:subscriptionId/cancel': '/account/shop',

      '/account/skin/update/profile': '/account/shop',
      '/account/skin/preferences': '/account/shop',
      '/account/skin': '/account/shop',
      '/account/skin/feedback': '/account/subscriptions',
      '/personalized-subscription': '/account/shop/skin',
      '/personalized-eye-cream-duo': '/account/shop/eye',
      '/personalized-face-cleanser': '/account/shop/skin/proven-cleanser',
      '/personalized-day-moisturizer': '/account/shop/skin/proven-spf',
      '/personalized-night-cream': '/account/shop/skin/proven-night-cream',
      '/personalized-day-eye-cream': '/account/shop/eye/day-eye-cream',
      '/personalized-night-eye-cream': '/account/shop/eye/night-eye-cream',
      '/personalized-serum': '/account/shop/serum',
      '/eye': '/account/shop/eye',
      '/serum': '/account/shop/serum',
      '/welcome-back': '/account/shop/skin',
      [redirectToCleanserPDP]: '/personalized-face-cleanser/',
      [redirectToMoisturizerPDP]: '/personalized-day-moisturizer/',
      [redirectToNightCreamPDP]: '/personalized-night-cream/',
      [redirectToEyeDuoPDP]: '/personalized-eye-cream-duo/',
      [redirectToDayEyeCreamPDP]: '/personalized-day-eye-cream/',
      [redirectToNightEyeCreamPDP]: '/personalized-night-eye-cream/',
      [redirectToShopPage]: '/account/shop/',
      [redirectToSerumPDP]: '/personalized-serum/',
      [redirectToAccountPage]: '/account/',
      [redirectTo422]: '/account/shop/onetime/hydration-boost-day-moisturizer/',
      [redirectToPaymentUpdatePage]: '/account/settings/info/payment',
      [redirectToSpotTreatmentPage]: '/spot-treatment-serum/'
    },
    [ONLY_ONE_TIME_PURCHASER]: {
      redirectionByPathOrEventName: {
        '/account/subscriptions': '/account/shop'
      },
      [HAS_SYSTEM_ONE_TIME]: {
        allowedPaths: ['/account/skin/update/profile', '/account/skin/update', '/account/skin']
      },
      [HAS_EYE_CREAM_DUO_ONE_TIME]: {}
    },
    [SUBSCRIBER]: {
      allowedPaths: [
        '/account/skin',
        '/account/skin/update',
        '/account/skin/update/profile',
        '/account/subscriptions',
        '/account/subscriptions/:subscriptionId',
        '/account/subscriptions/:subscriptionId/cancel'
      ],
      [ACTIVE_SUBSCRIBER]: {
        redirectionByPathOrEventName: {
          // '/account/skin/update/profile': '/account/subscriptions',
          '/account/skin/preferences': '/account/subscriptions'
        },
        [HAS_SYSTEM_ACTIVE_SUBSCRIPTION]: {
          redirectionByPathOrEventName: {
            [onAfterSkinQuiz]: '/account/subscriptions'
          },
          allowedPaths: ['/account/skin/preferences', '/account/skin/feedback']
        }
      },
      [INACTIVE_SUBSCRIBER]: {}
    }
  }
}

export default userTypeAccess

export const provenMainUrl = 'https://www.provenskincare.com'
export const provenTitle = 'PROVEN Skincare | Personalized Skincare Custom Made for You | PROVEN'
export const provenDescription =
  'As seen on Shark Tank; Personalized, clean, made in USA skin care custom formulated for you by Stanford Scientists; Winner of MIT’s AI Tech award, named by the WSJ and Allure as top skincare innovation'
export const provenName = 'PROVEN Skin Care'
export const provenLogo =
  'https://www.provenskincare.com/cdn-cgi/image/f=auto,onerror=redirect/https://media.provenskincare.com/img/150x150+logo.jpg'
export const provenLogoHeight = 150
export const provenLogoWidth = 150

export const quizTitlePrefix = 'Skin Genome Quiz: 47 Factors to Fix All Skin Concerns'
export const quizTitleSuffix = ' | PROVEN'
export const quizDescription =
  'Our Skin Genome Quiz analyzes 47 unique factors to create a formula based on your skin, lifestyle and environment.'

export const faqsTitle = 'Skin Care FAQ  | PROVEN'
export const faqsDescription =
  'Understanding your skincare routine is just as important as the routine itself. Check out answers to common PROVEN FAQs.'

export const pressTitle = 'PROVEN: As Seen on Shark Tank, Forbes, and much more! | PROVEN'
export const pressDescription =
  'Check out why everyone loves PROVEN skincare and all the press PROVEN has received over the last year! Click to read all the press articles!'

export const aboutTitle = 'About Us: Custom Skin Care by Stanford Scientists | PROVEN'
export const aboutDescription =
  "Say goodbye to 'difficult skin' and complicated skincare routines. Backed by scientific expertise and data, PROVEN products are tailor made for your skin."

export const contactUsTitle = 'Contact Proven Skincare | PROVEN'
export const contactUsDescription =
  "If you have questions about your skin care routine or you're struggling to find skin care products that work for you, don't hesitate to contact Proven Skincare!"

export const reviewsTitle =
  'Proven Skincare Reviews: Why Thousands Love PROVEN | Personalized Skincare | PROVEN'
export const reviewsDescription =
  'Come see why everyone is falling in love with PROVEN! Personalized skin care is the future, come read the thousands who agree!'

export const blogsTitle = 'Skincare Blog | PROVEN'
export const blogsDescription =
  'Browse through our detailed collection of blogs on all types of skincare, skin concerns, tips and more.'

export const quizConcernTitle = 'Personalized Skincare Quiz | PROVEN'
export const quizConcernDescription =
  "Take the 3 min skin quiz developed by Stanford scientists. We'll identify the top ingredients and formulations personalized for your skin type and lifestyle. As seen on Shark Tank."

export const quizEyeConcernTitle = 'Personalized Eye Care Quiz | PROVEN'
export const quizEyeConcernDescription =
  'Find your ideal eye treatment in just 3 minutes. Developed by Stanford experts, we customize solutions to target your specific under-eye concerns effectively. As seen on Shark Tank.'

export const quizSerumConcernTitle = 'Custom Serum Quiz | PROVEN'
export const quizSerumConcernDescription =
  "Discover your perfect serum with our 3-minute quiz, crafted by Stanford scientists. We pinpoint the best ingredients and serum formulations for your skin's unique needs. As seen on Shark Tank."

export const notFoundTitle = 'Page Not Found'
export const notFoundDescription = "This page doesn't exist."

export const socialLinks = {
  facebook: 'https://www.facebook.com/provenskincare',
  instagram: 'https://www.instagram.com/provenskincare',
  twitter: 'https://twitter.com/SkincareProven',
  linkedin: 'https://www.linkedin.com/company/provenskincare',
  youtube: 'https://www.youtube.com/provenskincare/'
}

export const provenOrganization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: provenName,
  url: provenMainUrl,
  sameAs: [socialLinks.facebook, socialLinks.instagram, socialLinks.twitter, socialLinks.linkedin],
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'San Francisco',
    addressRegion: 'CA',
    postalCode: '94107',
    streetAddress: '450 Townsend Street'
  },
  logo: provenLogo
}

export const LIGHTBOX_CDN_LINK =
  'https://www.lightboxcdn.com/vendor/6a101e6d-4628-4c23-a259-6a49477d0254/lightbox_inline.js'

export const TRACK_EVENT_ADD_TO_CART = 'Product Added'
export const TRACK_EVENT_ADD_TO_CART_2 = 'Product Added'
export const TRACK_EVENT_CONTINUE_TO_CART = 'continue_to_cart'
export const TRACK_EVENT_START_CHECKOUT = 'start_checkout'
export const TRACK_EVENT_CART_ADJUSTMENT = 'cart_adjustment'

export const TRACK_EVENT_ORDER_COMPLETED = 'Order Completed'

export const TRACK_CHECKOUT_TO_ORDER_COMPLETED_EVENTS = [
  'Checkout Started',
  TRACK_EVENT_START_CHECKOUT,
  'Checkout Step Viewed',
  'checkout_shipping',
  'skip_the_line',
  'Payment Info Entered',
  'checkout start - shippingAddress, chargebeeToken',
  'checkout start - shippingAddress, braintreeNonce',
  TRACK_EVENT_ORDER_COMPLETED
]

import { KEYWORD_EYE, KEYWORD_SERUM, KEYWORD_SKIN } from '../funnels'

export const onAfterAuthentication = 'onAfterAuthentication'
export const onAfterEyeQuiz = 'onAfterEyeQuiz'
export const onAfterSkinQuiz = 'onAfterSkinQuiz'
export const onAfterSerumQuiz = 'onAfterSerumQuiz'
export const onAfterAuthenticationWithGiftCard = 'onAfterAuthenticationWithGiftCard'

export const ON_AFTER_QUIZ_EVENT_BY_PRODUCT_KEYWORD = {
  [KEYWORD_EYE]: onAfterEyeQuiz,
  [KEYWORD_SKIN]: onAfterSkinQuiz,
  [KEYWORD_SERUM]: onAfterSerumQuiz
}

export const redirectToCleanserPDP = 'redirectToCleanserPDP'
export const redirectToMoisturizerPDP = 'redirectToMoisturizerPDP'
export const redirectToNightCreamPDP = 'redirectToNightCreamPDP'
export const redirectToEyeDuoPDP = 'redirectToEyeDuoPDP'
export const redirectToDayEyeCreamPDP = 'redirectToDayEyeCreamPDP'
export const redirectToNightEyeCreamPDP = 'redirectToNightEyeCreamPDP'
export const redirectToSerumPDP = 'redirectToSerumPDP'
export const redirectToShopPage = 'redirectToShopPage'
export const redirectToAccountPage = 'redirectToAccountPage'
export const redirectTo422 = 'redirectTo422'
export const redirectToPaymentUpdatePage = 'redirectToPaymentUpdatePage'
export const redirectToSpotTreatmentPage = 'redirectToSpotTreatmentPage'

export const CRM_REDIRECT_PARAMS_MAPPING = [
  redirectToCleanserPDP,
  redirectToMoisturizerPDP,
  redirectToNightCreamPDP,
  redirectToEyeDuoPDP,
  redirectToDayEyeCreamPDP,
  redirectToNightEyeCreamPDP,
  redirectToSerumPDP,
  redirectToShopPage,
  redirectToAccountPage,
  redirectTo422,
  redirectToPaymentUpdatePage,
  redirectToSpotTreatmentPage
]

export const EYE_CREAM_DUO_YOTPO_PRODUCT_ID = 823500

export const BASE_PRODUCT_ID_AND_YOTPO_PRODUCT_ID_MAP = {
  serum: 'serum-all',
  'eye-cream-duo': EYE_CREAM_DUO_YOTPO_PRODUCT_ID,
  'personalized-skincare-system': PERSONALIZED_SKINCARE_SYSTEM_YOTPO_PRODUCT_ID
}

export const PERSONALIZED_SKINCARE_SYSTEM_YOTPO_PRODUCT_ID = 823100

export const YOTPO_CONFIG_MAP_SYSTEM_KEY = 'personalized-skincare-system'
export const YOTPO_CONFIG_MAP_EYE_CREAM_DUO_KEY = 'eye-cream-duo'
export const YOTPO_CONFIG_MAP_SERUM_KEY = 'serum'

export const YOTPO_CONFIG_MAP = {
  [YOTPO_CONFIG_MAP_SYSTEM_KEY]: {
    product_yotpo_id: PERSONALIZED_SKINCARE_SYSTEM_YOTPO_PRODUCT_ID,
    product_title: 'Personalized Skincare System',
    product_url: 'https://www.provenskincare.com/personalized-subscription'
  },
  [YOTPO_CONFIG_MAP_EYE_CREAM_DUO_KEY]: {
    product_yotpo_id: EYE_CREAM_DUO_YOTPO_PRODUCT_ID,
    product_title: 'Eye Cream Duo',
    product_url: 'https://www.provenskincare.com/personalized-eye-cream-duo/'
  },
  [YOTPO_CONFIG_MAP_SERUM_KEY]: {
    product_yotpo_id: 'serum-all',
    product_title: 'Serum',
    product_url: 'https://www.provenskincare.com/personalized-serum'
  }
}

export const TRANSITION_QUESTION_TITLES = [
  `Do you experience skin tightness or discomfort?`,
  `Can you see clusters of surface capillaries on your skin?`,
  `How about lines, wrinkles or crow's feet around the eyes?`,
  `And are you concerned about volume loss on your facial skin?`,
  `Is the hyperpigmentation from acne or other skin injuries?`,
  `How often do you experience those bumps/pimples?`,
  `Does your skin feel rough, patchy or uneven?`
]

export const TRANSITION_PREV_QUIZ_NAME = {
  firmness: 'elasticity_volume',
  redness: 'redness_vessels',
  pigmentation: 'pigmentation_acne',
  sensitivity: 'sensitivity_tightness',
  wrinkles: 'wrinkle_texture',
  dryness: 'dryness_patchy',
  acne: 'acne_freq_papule'
}

export const CONCERN_NAME_LIST = [
  'firmness',
  'redness',
  'pigmentation',
  'sensitivity',
  'wrinkles',
  'dryness',
  'acne'
]

export const CONCERN_NAME_SUFFIX = {
  firmness: 'elasticity',
  redness: 'redness',
  pigmentation: 'pigmentation',
  sensitivity: 'sensitivity',
  wrinkles: 'wrinkle',
  dryness: 'dryness',
  acne: 'acne'
}
